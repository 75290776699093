import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import PostList from "../components/postList"
import Header from "../components/header"
import Meta from "../components/meta"
import Pagination from "../components/Pagination"

const Category = props => {
  const { data, pageContext } = props
  const posts = data.allMdx.nodes
  const { title: siteTitle } = data.site.siteMetadata
  const totalCount = pageContext.count
  const { name: category, loc } = pageContext

  const header = (
    <Header
      title={category}
      description={`${totalCount} post${totalCount === 1 ? "" : "s"}`}
      loc={loc}
    />
  )
  return (
    <Layout loc={loc} header={header}>
      <Helmet title={`${category} | ${siteTitle}`} />
      <Meta isRoot={false} title={`${category} | ${siteTitle}`} />
      <PostList posts={posts} title="" />
      <Pagination pageContext={pageContext} pathPrefix="/" />
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage($name: String!, $skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      filter: { frontmatter: { categories: { eq: $name } } }
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          tags
          categories
          date(formatString: "YYYY.MM.DD")
          description
          modified
          uri
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 1920, webpQuality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
